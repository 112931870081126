import {} from "@heroicons/react/24/outline";
import SimpleLink from "../../components/SimpleLink";

function Footer() {
  return (
    <footer
      id="app-footer"
      className="inria w-auto bg-zinc-700 pt-8 pb-8 sm:pb-6 px-8 bg-gradient-to-br from-zinc-900 to-zinc-800"
    >
      <div className="max-w-[1280px] mx-auto5">
        <div className="flex flex-col sm:flex-row text-center justify-between">
          {/* <LogoSVG className="block h-[100px] md:h-[150px] w-auto fill-zinc-900" /> */}
          <div className="w-full ml-0 sm:ml-8 pt-4 pb-16 sm:pb-0 col-span-3 text-zinc-50 text-sm  drop-shadow-sm">
            <p className="text-4xl">TEL : 0507.1332.1636</p>
            <p className="py-3">AM 10:00 ~ PM 06:00</p>
            <div className="py-4 leading-6">
              <p>
                <span className="font-bold">COMPANY :</span> 섬마을 과자점 |{" "}
                <span className="font-bold">CEO :</span> 김지은
              </p>
              <p>
                <span className="font-bold">BUSINESS LICENSE :</span>{" "}
                123-45-67890 <SimpleLink href="#" label="[ 사업자정보확인 ]" />
              </p>
              <p>
                <span className="font-bold">ADDRESS :</span> 제주 제주시 관덕로
                41 건물뒷편 1층
              </p>
              <p>
                <span className="font-bold">MAIL :</span>{" "}
                isbakeshop777@gmail.com
              </p>
            </div>
            <div className="">
              <SimpleLink href="#" label="회사소개" className="pr-2" />
              <SimpleLink href="#" label="이용약관" className="px-2" />
              <SimpleLink href="#" label="개인정보처리방침" className="px-2" />
              <SimpleLink href="#" label="협력 및 제안문의" className="px-2" />
            </div>

            <p className="text-white pt-4 text-sm">
              COPYRIGHT 2023© Is bake shop ALL RIGHT RESERVED.
            </p>
          </div>

          <div className="fixed right-2 sm:right-[3vw] lg:right-[5vw] bottom-4 sm:bottom-6 z-[1000] min-h-auto  flex items-center justify-end scale-100 sm:scale-125">
            <div className="min-h-auto w-auto flex flex-row justify-between bg-zinc-800 rounded-full p-2 shadow-[inset_0_0px_2px_rgba(0,0,0,.5)]">
              <a
                href="https://map.naver.com/v5/entry/place/1459384968?c=15,0,0,0,dh"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  src="./sns/naver.svg"
                  width="35px"
                  alt="Logo of Naver. A white letter N on a green background."
                  lang="en"
                  className="mr-2 my-0 footer-icon drop-shadow-[0px_0px_1px_rgba(0,0,0,.5)] rotate-0 hover:-rotate-12 transition-transform ease-in-out duration-350"
                />
              </a>
              <a
                href="http://instagram.com/is_bake_shop"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="./sns/instagram.svg"
                  width="35px"
                  alt="Logo of Instagram."
                  className="my-0 footer-icon drop-shadow-[0px_0px_1px_rgba(0,0,0,.5)] rotate-0 hover:-rotate-12 transition-transform ease-in-out duration-350"
                />
              </a>
              {/* <a href="#" target="_blank" rel="noreferrer">
                <img
                  src="./sns/facebook.svg"
                  width="35px"
                  alt="Logo of Facebook."
                  className="ml-2 my-0 footer-icon drop-shadow-[0px_0px_1px_rgba(0,0,0,.5)] rotate-0 hover:-rotate-12 transition-transform ease-in-out duration-750"
                />
              </a> */}
            </div>
          </div>
        </div>
      </div>
      <div className={"border-t-[1px] border-zinc-700 w-2/3 sm:1/2 md:w-1/4 mx-auto my-4"}></div>
      <p className={"inria uppercase text-center text-xs text-slate-300 tracking-widest"}>{"{{ code && design }} by "}<a className={"text-teal-300 font-extrabold underline"} href={"https://bibimweb.com"}>bibimweb</a></p>
      {/* <div className="w-[80vw] mx-auto border-t-[1px] bg-opacity-60 border-zinc-700"></div> */}
    </footer>
  );
}

export default Footer;
