import { Disclosure, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  XMarkIcon,
  ArrowTopRightOnSquareIcon,
} from "@heroicons/react/24/outline";

import SVGLogo from "../assets/logo.svg";
import { classNames } from "../helpers";

const navigation = [
  { name: "Brand", href: "#bakery", current: false },
  { name: "Dacquoise", href: "#dacquoise", current: false },
  { name: "Sand Cookie", href: "#sand", current: false },
  { name: "Products", href: "#products", current: false },
  {
    name: "Store",
    href: "https://smartstore.naver.com/is_bake_shop/products/6309250717",
    target: "_blank",
    current: false,
    isButton: true,
    icon: <ArrowTopRightOnSquareIcon className="h-4 w-4 inline" />,
  },
];

function NavBar() {

  return (
    <Disclosure as="nav" className={classNames("fixed top-0 w-full z-[9999]")}>
      {({ open }) => (
        <>
          <div
            className={classNames(
              "transition-all duration-150 bg-gradient-to-tr from-zinc-900 to-zinc-800 z-[100]",
              "relative mx-auto px-8 sm:px-12 md:px-16 lg:px-20 py-5",
              "w-full transition-colors"
              // "flex items-center justify-center"
            )}
          >
            <div className="relative ">
              <div className="absolute inset-y-0 left-0 flex items-center md:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button
                  className={classNames(
                    open ? "bg-zinc-900 bg-opacity-40" : "",
                    "inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  )}
                >
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon
                      className="block h-6 w-6 text-zinc-100"
                      aria-hidden="true"
                    />
                  ) : (
                    <Bars3Icon
                      className="block h-6 w-6 text-zinc-100"
                      aria-hidden="true"
                    />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:justify-between">
                <div className="flex flex-shrink-0 items-center">
                  <a href="/">
                    <img
                      className="block h-12 w-auto lg:hidden"
                      src={SVGLogo}
                      alt="Logo of the company, three shape of different cake."
                    />
                    <img
                      className="hidden h-12 w-auto lg:block transition-transform"
                      src={SVGLogo}
                      alt="Logo of the company, three shape of different cake."
                    />
                  </a>
                </div>
                <div className="hidden ml-2 sm:ml-10 md:ml-20 sm:flex items-center">
                  <div className="flex space-x-2 md:space-x-4">
                    {navigation.map((item) => (
                      <a
                        key={"menu-main-" + item.name}
                        href={item.href}
                        target={item.target}
                        className={classNames(
                          item.current
                            ? "bg-gray-900 text-white"
                            : "text-gray-200 transition-all",
                          "px-3 py-2 rounded-md text-sm md:text-lg font-medium drop-shadow-[0_1px_1px_rgba(0,0,0,.35)]",
                          "flex items-center inria",
                            item.isButton ? "font-extrabold bg-green-600 bg-opacity-90 hover:bg-opacity-50" : "hover:bg-white hover:bg-opacity-20 hover:text-white"
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        <p className="leading-none mr-1">
                          {item.name.toUpperCase()}
                        </p>
                        {item.icon}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Transition
            show={open}
            className="relative transition-all z-0 duration-[750ms] ease-in-out bg-zinc-800 bg-opacity-80"
            // enter="  "
            enterFrom="scale-y-[.75] -translate-y-full"
            enterTo="scale-y-100 -translate-y-0"
            // leave="transition-all duration-[750ms] ease-in-out"
            leaveFrom="scale-y-100 translate-y-0"
            leaveTo="scale-y-[.75] -translate-y-full"
          >
            <Disclosure.Panel static className={classNames("transition-all")}>
              {({close}) => (
              <div className="space-y-1 px-2 pt-2 pb-3 h-screen" onClick={ () => {console.log('TEST'); close();}}>
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={"menu-main-" + item.name}
                    as="a"
                    href={item.href}
                    target={item.target}
                    className={classNames(
                      item.current
                        ? "text-white"
                        : "text-gray-300",
                      "block px-3 py-2 rounded-md text-base font-medium inria",
                      "bg-zinc-900 transition-all",
                        item.isButton ? "font-extrabold bg-green-600 hover:bg-green-800" : "hover:bg-zinc-700 hover:text-white"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    <p className="leading-1 flex items-center justify-center">
                      {item.name.toUpperCase()}{" "}
                      <span className="ml-2 leading-[0]">{item.icon}</span>
                    </p>
                  </Disclosure.Button>
                ))}
              </div>
              )}
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
}

export default NavBar;
