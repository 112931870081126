import {classNames} from "../../helpers";

function Header() {
    return (
        <header className="">
            <div className={classNames(
                "min-h-screen w-full text-white",
                "flex flex-col items-center justify-center overflow-hidden",
                "animate-slideBTU"
            )}>
                <h1 className={classNames(
                    "text-[15vw] sm:text-[10vw] font-bold",
                    "leading-none drop-shadow-[2px_2px_1px_rgba(0,0,0,.2)]"
                )}>
                    섬마을과자점
                </h1>
                <div className={"w-1/3 my-4 border-t-8 border-beigedark drop-shadow-none"}></div>
                <p className="text-center text-lg sm:text-xl md:text-2xl lg:text-3xl mb-8 drop-shadow-[2px_2px_1px_rgba(0,0,0,.2)]">
                  제주의 본질적인 맛을 담은 <br/>닥쿼아와 샌드 쿠키를 즐겨보세요.
                </p>
            </div>
        </header>
    );
}

export default Header;
