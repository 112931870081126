import SectionTitle from "../../components/SectionTitle";
import React from "react";
import {ShoppingCartIcon} from "@heroicons/react/24/solid";

function Sand() {
    return (
        <section id="sand" className="py-32 bg-zinc-50">
            <div className="w-[80vw] max-w-[1280px] mx-auto flex flex-col items-center justify-center">
                <div className="flex flex-col items-center my-0 sm:my-6">

                    <div className="w-[80vw] max-w-[1280px] mx-auto flex items-center justify-center">
                        <div className="flex flex-col items-center">
                            <SectionTitle label="Sablé Doré"/>
                        </div>
                    </div>

                    <div className={"grid grid-cols-1 sm:grid-cols-2 items-center justify-center my-2"}>
                        <div
                            className={"flex flex-row sm:flex-col w-full items-center sm:items-end pr-0 sm:pr-6 md:pr-12 mb-6 sm:mb-0 justify-center"}>
                            <div id={"bg-circle"} className={"my-2 flex items-center justify-center drop-shadow-sm"}
                                 key={"sable-" + Math.floor(Math.random() * 999)}
                            >
                                <img src={"/sable/sables_2.png"} className={""} width={"300px"}
                                     alt={'Three delicious sand cookies'}/>
                            </div>
                        </div>
                        <div className="py-10 px-4 z-10">
                            <h4 className="intra text-3xl font-[900] uppercase text-zinc-900">
                                DOUX & SAVOUREUX
                            </h4>
                            <p className="inria mt-8 text-base text-left">
                                독특하고 기발한 디저트인 다쿠아즈를 소개합니다! 프랑스인에게서 영감을 받은 이 천상의 창조물은 감각에 진정한 즐거움을 선사합니다.
                                <br/>
                                <br/>
                                가볍고 바람이 잘 통하는 머랭과 버터크림 층으로 제작된 당사의 다쿠아즈는 매력적이고 잊을 수 없는 장난스러운 구름 모양이 특징입니다.
                                <br/>
                                <br/>
                                우리의 다쿠아즈는 친밀한 모임부터 대규모 축하 행사에 이르기까지 모든 행사에 적합합니다.
                                독특하고 기억에 남을 만한 모양은 손님들에게 깊은 인상을 남길 것이며,
                                섬세한 질감과 맛있는 맛은 손님들이 계속해서 다시 찾게 만들 것입니다.
                            </p>

                            <p className={"mt-4"}>
                                <a href="https://smartstore.naver.com/is_bake_shop/products/8522674790"
                                   target={"_blank"} rel={"noreferrer"}
                                   className="bg-green-600 hover:bg-green-800 text-white font-bold py-2 px-4 rounded-full inline-block transition duration-300 ease-in-out">
                                    <ShoppingCartIcon className="transition-all h-6 w-6 aspect-square inline"/>
                                    <span className="ml-2">구매하기</span>
                                </a>
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
}

export default Sand;
