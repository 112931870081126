import { useState } from "react";

import { Transition } from "@headlessui/react";
import { classNames } from "../../helpers";
import SlideUpWrapper from "../../components/SlideUpWrapper";

interface ProductTileProps {
  bgUrl: string;
  label?: string;
  description?: string;
}

function ProductTile(props: ProductTileProps) {
  const [isShowing, setIsShowing] = useState(false);

  return (
    <SlideUpWrapper>
      <div
        className="w-full max-w-[60vw] sm:max-w-none mx-auto col-span-1 aspect-square overflow-hidden hover:cursor-pointer "
        onClick={() => setIsShowing((isShowing) => !isShowing)}
      >
        <div
          className={classNames(
            props.bgUrl ?? "",
            isShowing ? "bg-[length:120%_120%]" : "bg-[length:100%_100%]",
            "bg-center h-full w-full transition-all duration-700",
            "hover:shadow-[inset_0px_0px_5px_rgba(60,60,65,.5)] shadow-[inset_0px_0px_15px_rgba(60,60,65,.3)] ease-in-out"
          )}
        >
          <Transition show={isShowing} className="w-full h-full">
            {/* Background overlay */}
            <Transition.Child
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="h-full w-full"
            >
              {/* ... */}
              <div className="bg-zinc-900 bg-opacity-40 h-full w-full flex flex-col items-center justify-center p-6">
                <p className="text-2xl text-white font-bold drop-shadow-xl">
                  {props.label}
                </p>
                <p className="text-sm text-white text-center">
                  {props.description}
                </p>
              </div>
            </Transition.Child>
          </Transition>
        </div>
      </div>
    </SlideUpWrapper>
  );
}

export default ProductTile;
